


































































































  import { Validator } from '@/constant/Mixins';
  import { Toaster } from '@/constant/Toaster';
  import { ServerResponse } from '@/services/response.types';
  import { UserModel } from '@/store/auth/types';
  import { ProductTestimonyModel, TestimonyModel } from '@/store/product/types';
  import Component from 'vue-class-component';
  import { Mixins } from 'vue-property-decorator';
  import { Action, State } from 'vuex-class';

  @Component({
    name: 'reviewProduct',
    components: {},
    mixins: [],
  })
  export default class reviewProduct extends Mixins(Validator) {
    @Action('GetTestimony') GetTestimony!: () => Promise<ProductTestimonyModel>;
    @Action('AddUlasan') AddUlasan!: (form: FormData) => Promise<ServerResponse>;

    @State('testimony') rajaPremiTestimony!: ProductTestimonyModel;
    @State('user', { namespace: 'auth' }) user!: UserModel;

    public activeNav = 0;
    public userReviews: TestimonyModel[] = [];
    public showFormReview = false;
    public type: any = null;
    public product: any = null;

    public formReview = {
      customer_name: '',
      testimonial_description: '',
      rating: '',
      customer_email: '',
      provider_id: null,
    };


    public get productTestimony() : ProductTestimonyModel {
      return this.$store.state.product[this.product].testimony
    }


    public created(): void {
      this.formReview.provider_id = this.$route.query.p;
      if (this.user.full_name) this.formReview.customer_name = this.user.full_name;
      if (this.user.email) this.formReview.customer_email = this.user.email;

      let match = /compare\/([a-z-]+)\/([a-z]+).+/g.exec(location.pathname) || [];
      this.type = match[0];
      if(typeof match[1] === 'undefined') {
        Toaster.Warning("silahkan pilih ulang produk", {title: "Produk tidak di temukan!"})
        // this.$router.push("/")
      }else this.product = (match[1] as string).replace("-","");
    }

    public mounted(): void {
      this.GetData()
      let act = localStorage.getItem('rv.m.lact');
      if (act) {
        this[act]();
        localStorage.removeItem('rv.m.lact');
      }
    }

   /**
    * GetUlasan
    */
   public GetUlasan(quotation_id: string): Promise<ServerResponse> {
     return new Promise((resolve, reject) => {
       if(this.product) this.$store.dispatch(`product/${this.product}/GetUlasan`, quotation_id).then(resolve).catch(reject)
       else reject("Product is undefined")

     })
   }

    /**
     * GetData
     */
    public GetData() {
      this.showFormReview = false;
      this.GetTestimony();
      this.GetUlasan(this.$route.query?.q as string).then(() => this.OnChangeTab(this.activeNav));
    }

    /**
     * OnChangeTab
     */
    public OnChangeTab(indexNav) {
      let dataRef = indexNav === 0 ? 'productTestimony' : 'rajaPremiTestimony';
      let testimony: ProductTestimonyModel = this[dataRef];
      this.userReviews = testimony.list_testimonial;
    }

    /**
     * AddReview
     */
    public AddReview() {
      if (this.user.email.length > 0) {
        this.showFormReview = true;
      } else {
        localStorage.setItem('redirect', JSON.stringify({ name: this.$route.name, query: this.$route.query }));
        localStorage.setItem('rv.m.lact', 'AddReview');
        this.$router.push({ name: 'login' }).then(() => Toaster.Make('Silahkan login sebelum menambahkan ulasan produk', { variant: 'info', title: 'Login diperlukan!' }));
      }
    }

    /**
     * OnSubmitReview
     */
    public OnSubmitReview(ev: Event) {
      this.AddUlasan(new FormData(ev?.target as HTMLFormElement)).then(this.GetData);
    }
  }
